.get-in-touch-btn {
  text-align: center;
  text-decoration: none !important;
  background-color: #8a74ea;
  max-width: 500px;

  border-color: none;
  border-radius: 5px;

  display: flex;
  margin: auto;
  margin-top: 3vh;
  margin-bottom: 5vh;
  font-family: "Poppins", sans-serif;

  font-size: 1.2rem;
  /* font-weight: 1000; */
  font-weight: 700;

  /* border: 2px solid black; */

  padding: 15px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  /* background-color: #39a0ed; */
  /* background-color: black; */

  justify-content: center;

  text-decoration: none;
  border: none;
  /* border-radius: 5px; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;
}

.get-in-touch-btn:hover {
  /* background-color: #0277bd; */
  /* background-color: #0d47a1; */
  background-color: #cc99ff;

  /* border: 2px solid white; */
  color: white;
}
.get-in-touch-btn:active {
  color: white;
}

.info-text {
  font-family: "Poppins", sans-serif;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 600px) {
  .get-in-touch-btn {
    /* width: 25%; */
  }
}
