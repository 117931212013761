.contact-container {
  /* position: relative; */
  min-height: 100vh;
  margin-top: 10vh;
  align-items: center;
  font-family: "Poppins", sans-serif;
  /* text-align: center; */
}

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
}

.contact-header {
  margin-top: 57px;
  text-align: left !important;
}

.contact-h2 {
  margin: 35px;
}

.contact-h2-container {
  /* width: 100%; */
  /* margin-left: 100px;
  margin-right: 100px; */
}

.success-alert {
  background-color: #43a047;

  color: white;
}

.sending-alert {
  background-color: #42a5f5;
  color: white;
}

.sending-alert-text {
  /* display: inline-block; */
  position: absolute;
  top: 1vh;
  left: 34%;
}

.alert {
  margin-top: 5vh;
  height: 5vh;
  text-align: center;
  padding: 5px;
}

.fail-alert {
  background-color: #e53935;

  color: white;
}

.success-alert-text {
  font-size: small;
  text-align: left;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 30%;
}

.contact-img {
  top: 40vh;
}

.contact-btn:hover {
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
  color: #efefef;
  transform: translateY(-4px);
}

.contact-btn {
  text-align: center;
  text-decoration: none !important;

  width: 100%;

  display: flex;
  margin: auto;
  margin: 5vh 0vh;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: white;
  background: rgb(0, 74, 134);
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .contact-form {
    width: 500px;
    margin: auto;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }

  .contact-h2 {
    margin: 35px 25%;
  }
}
