.venture-body {
  margin-top: 90px;
  text-align: center;
  padding: 0px 5%;
  font-family: "Poppins", sans-serif;
}

.venture-title {
  font-size: 2.3rem;
}

.venture-subtitle {
}

.s2capital {
  margin-top: 30px;
}

.awk-lottie {
  position: relative;
  left: -40px;
  margin: 4vh 0vh;
}

.venture-text {
  /* margin: 10px 200px; */
  text-align: left;
  margin: 40px 10px;
}

.venture-lottie {
  margin: 40px 0px;
}

/* old css */

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
}

.center-image {
  position: relative;
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 30%;
}

.contact-btn:hover {
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
  color: #efefef;
  transform: translateY(-4px);
}

.contact-btn {
  text-align: center;
  text-decoration: none !important;

  width: 100%;

  display: flex;
  margin: auto;
  margin: 5vh 0vh;
  /* font-family: "Montserrat", sans-serif; */
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: white;
  background: rgb(0, 74, 134);
  background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  );
  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .contact-form {
    width: 500px;
    margin: auto;
  }

  .sending-alert-text {
    left: 40%;
  }

  .alert {
    padding: 10px;
  }

  /* new code */

  .venture-text {
    /* margin: 10px 200px; */
    margin: 40px 15%;
  }
}
