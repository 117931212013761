// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 17rem);
  gap: 2rem;
}

.card-text {
  padding-top: 1.2rem;
  font-size: 1rem;
  color: white;
  font-weight: bold;
}

.card-text-long {
  padding-top: 0.6rem;
  font-size: 1rem;
  color: white;
  font-weight: bold;
}

.item {
  position: relative;
  height: 19rem;
  background-color: lightGrey;
  overflow-y: hidden;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  h3 {
    margin: 0;
    display: block;
    // background-color: rgb(8, 0, 122);
    // background-color: #9933ff;
    background-color: #8a74ea;
    // background-color: #cc99ff;

    // background-color: #000088;

    // color: black;
    // position: relative;
    // padding: 2rem;
    padding-bottom: 1.7rem;
    font-size: 1.4rem;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:hover
    // &:focus
    {
      ~ .item__overlay {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  // padding-bottom: 2rem;
}

.item__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  transition: transform 300ms;
  // background-color: (rgb(54, 47, 143));
  // background-color: #283593;
  // background-color: #cc99ff;
  background-color: #8a74ea;
  transform: translate3d(0, calc(100%), 0);
  color: white;
  font-weight: bold;
}

.item__body {
  flex-grow: 1;
  padding: 1rem;

  p {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .card-text-long {
    padding-top: 1.2rem;
  }
}

@media only screen and (min-width: 993px) {
  .card-text-long {
    padding-top: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
}
