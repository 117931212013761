.custom-navbar {
  background-color: #ffffff !important;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.nav-logo {
  position: relative;
  width: 30px;
  /* margin-right: 25px; */

  display: inline-block;
}

.nav-item-link {
  margin-top: 8px;
}

.nav-company-name {
  color: #121212;
  font-size: x-large;
  /* position:  */
  display: inline-block;
  position: relative;
  top: 5px;
}

.nav-company-name:hover {
  /* color: #9e9e9e; */
}

.navbar-light {
  box-shadow: none !important;
}

.nav-link-logo {
  padding: none !important;
  margin-top: 0 !important;
  text-decoration: none !important;
  align-items: center;
}

.nav-link-logo:hover {
  /* opacity: 50%; */
}

@media only screen and (max-width: 600px) {
  /* .nav-logo {
    width: 30px;
  } */
  .nav-logo {
    margin-right: 15px;
  }
}

.nav-container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  background-color: whitesmoke !important;
}

.dropdown-menu {
  background-color: #1e1e1e !important;
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

.dropdown-item {
  color: #121212 !important;
}

.dropdown-toggle {
  font-size: medium;
}

.dropdown-item:hover {
  background-color: #121212 !important;
}

.navbar-radius {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.navbar-collapse {
  position: relative;
  right: 0;
}

.custom-navbar-brand {
  color: #e1e1e1 !important;
}

.custom-navbar-brand:hover {
  color: #9e9e9e !important;
}

.navbar-nav {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .nav-company-name {
    padding-left: 15px;
    padding-right: 15px;
  }
}
