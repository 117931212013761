body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */

  font-family: "Vollkorn", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #ffffff !important;
  color: #121212 !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url(../src/resources/Gilroy-ExtraBold.otf) format("otf");
}

.content-wrapper {
  padding-top: 1vh;
  padding-bottom: 5vh;
}

.outerlink {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: #57479e;
}

.outerlink:hover {
  color: #685ba3;
  text-decoration: none;
}

.act-btn:hover {
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);
  color: #57479e;
  transform: translateY(-4px);
}

.act-btn-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.act-btn {
  text-align: center;
  text-decoration: none !important;

  width: 50%;

  display: flex;
  margin: auto;
  margin-top: 5vh;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;
  color: white;
  background: rgb(0, 74, 134);
  /* background: linear-gradient(
    120deg,
    rgb(15, 134, 231) 50%,
    rgba(0, 212, 255, 1) 100%
  ); */
  background-color: #8b5567 !important;

  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

.custom-header {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
  font-size: 2.1rem !important;
  color: #212529;

  margin-top: 25px;
  text-align: left !important;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .home-image {
    width: 50%;
  }

  .act-btn {
    width: 50%;
  }
}
