.gallery-img-container {
  width: 100%;
  overflow: hidden !important;
  text-align: center;
  align-items: center;
  margin: 20px 0px;

  transition: all 0.5s ease 0s;
}

.learn-more-btn {
  text-align: center;
  text-decoration: none !important;
  width: 75%;

  margin: auto;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 15px;

  justify-content: center;

  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;

  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 10px;

  position: absolute;
  bottom: 7%;
  left: 12%;
}

.card-text {
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  /* letter-spacing: 1px; */
  padding-left: 10px;
  padding-right: 10px;

  color: rgb(63, 63, 63);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 500;
  font-size: 2rem;
}

.item {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
}

.card-text-long {
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  /* letter-spacing: 1px; */
  font-size: small !important;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  padding-top: 20px !important;
}

.gallery-img {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.project-description-container {
  /* width: 100%;
  height: 100%;
  margin-right: 15px;
  margin-left: 15px;
  overflow: hidden; */
}

.card-col {
  /* background-color: blue; */
  /* width: 50%; */
  /* margin: 20px; */
  overflow: hidden;
}

.project-description {
  /* flex: 0 0 25%; */
  /* max-width: 25%; */
  /* border-radius: 50%; */
  display: block;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  display: inline-block;
  width: 80%;
  height: 80%;
  margin-bottom: 15px;
  padding-top: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  left: 0;
  top: 0;

  font-size: 1.4rem;
  font-weight: bold;
  position: absolute;

  background-color: blue;

  margin-top: 20px;
  /* margin-left: 20px; */

  color: white !important;
  opacity: 100%;
}

.project-card-btn {
  width: 100px;
  height: 20px;
  /* z-index: 100; */
  /* position: absolute; */
  position: absolute;
  z-index: 1;
  top: 50%;
}

/* .project-img-page {
  width: 50%;
} */

@media only screen and (min-width: 600px) {
  .card-text-long {
    /* margin-top: 10px; */
  }
}
