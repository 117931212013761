.lottie-section-container {
  padding-bottom: 2rem;
  overflow: hidden;
}

.get-in-touch-btn-1 {
  text-align: center;
  text-decoration: none !important;
  background-color: #8a74ea;
  max-width: 200px;
  max-height: 60px;

  border-color: none;
  border-radius: 5px;

  display: flex;
  /* margin: auto; */
  margin-top: 3vh;
  margin-bottom: 5vh;
  font-family: "Raleway", sans-serif;

  font-size: 1.2rem;
  /* font-weight: 1000; */
  font-weight: 700;

  /* border: 2px solid black; */

  padding: 15px;
  color: white;
  /* color: #4b3f81; */

  /* background-color: #39a0ed; */
  /* background-color: black; */

  justify-content: center;

  text-decoration: none;
  border: none;
  /* border-radius: 5px; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;
  margin: auto;
}

.get-in-touch-btn-1:hover {
  /* background-color: #0277bd; */
  /* background-color: #0d47a1; */
  background-color: #942aff;

  /* border: 2px solid white; */
  color: white;
}
.get-in-touch-btn-1:active {
  color: white;
}

.about-job-header-1 {
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-weight: 600;
}

.about-description {
  /* margin: 30px 0px; */
  margin-bottom: 2.5rem !important;
  /* padding: 0px !important; */
  /* color: white; */
}

.about-text {
  color: black;
  margin: 30px 0px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.about-title {
  color: black;
  font-family: "Poppins", sans-serif;
}

.category-img-container-3 {
  margin-top: 5%;
  margin-bottom: 5%;

  text-align: center;
  /* height: 22vh; */
  overflow: hidden;
  text-align: center;
  /* align-items: center; */
  /* display: inline-block; */
}

.services-container-1 {
  /* margin-top: 30px; */
  margin-bottom: 30px;
  padding-top: 30px;
  /* padding: 30px !important; */
  /* border: 1px solid black; */
  /* border-radius: 25pt; */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.list {
  padding: 0px 20px;
  font-family: "Poppins", sans-serif;
}

.lottie-section {
  /* display: inline-block; */
  /* width: 100vh; */

  /* overflow: visible; */
}

.lottie-section-left {
  /* display: inline-block; */
  /* margin-top: 40px; */
  width: 100%;
  /* margin-left: 5%; */
}

.text-section {
  /* display: inline-block; */
  text-align: center;
  width: 100%;
  margin: 0px !important;
}

.text-section-left {
  /* margin-left: 5%; */
  /* display: inline-block; */
  text-align: center;
  width: 100%;
}

.category-img-3 {
  display: inline-block;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
}

@media only screen and (min-width: 600px) {
  .lottie-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
  }
  .lottie-section-left {
    width: 45%;
    display: inline-block;
    margin-left: 5%;
  }

  .text-section {
    width: 45%;
    text-align: left;
    display: inline-block;
    /* text-align: center; */
  }

  .text-section-left {
    width: 45%;
    text-align: left;
    display: inline-block;
    margin-left: 5%;
  }
  .get-in-touch-btn-1 {
    /* margin: 0; */
  }
}
