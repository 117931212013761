.team-container {
  margin-top: 12vh;
}

.category-img-container-2 {
  text-align: center;
  /* height: 22vh; */
  overflow: hidden;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.category-img-2 {
  display: inline-block;
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  margin-top: 30px;
}

.team-text {
  text-align: left;
  margin-bottom: 0;
}
.indent {
  text-indent: 30px;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-name {
  /* flex-flow: column; */
  /* padding: 25px 0px; */
  margin: 0;
  padding: 0;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}
/* @media only screen and (min-width: 600px) {
  .category-img-2 {
    display: inline-block;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
} */
