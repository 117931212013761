/* .work-lottie-container {
} */
.work-link {
  text-decoration: none !important;
  color: inherit;
}

.subfooter {
  color: grey !important;
  margin-top: 4vh;
}

.home-page-container {
  margin-top: 57px;
  min-height: 75vh;
}

.work-text {
  margin: 2.5rem 0rem;
  text-align: center;
}

.work-header {
  margin-bottom: 2rem;
}

.work-subheader {
  margin: 2rem 0rem;
}

.services-container {
  /* margin-top: 30px; */
  margin-bottom: 30px;
  padding-top: 30px;
  /* padding: 30px !important; */
  /* border: 1px solid black; */
  /* border-radius: 25pt; */
  /* background-color: #eeeeee !important; */
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.category-img-container {
  text-align: center;
  /* height: 20vh; */
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.category-img {
  display: inline-block;
  height: 10vh;
  margin-bottom: 2vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.about-job-header {
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
}

.about-description {
  /*width: 50%;*/
  padding-right: 5%;
  padding-left: 5%;
  margin-bottom: 4vh;
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  font-size: medium;
}

.top-tab-btn-container {
  /* width: 100vw; */
  text-align: center;
  /* border: 1.5px solid black; */
  /* padding-left: 1px;
  padding-right: 1px; */
  padding-top: 2px;
  padding-bottom: 2px;

  border-radius: 100px;

  margin: 2rem 0px;

  /* margin: 10px; */
  /* position: absolute;
  width: 100vw;
  left: 0;
  padding-left: 20%; */

  /* position: absolute;
  left: 0;
  width: 100%; */
}

.top-tab-btn-selected {
  text-align: center;
  text-decoration: none !important;
  background-color: #8a74ea !important;

  font-family: "Raleway", sans-serif;

  font-size: 0.85rem;
  font-weight: 600;

  color: white !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;

  text-decoration: none;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;

  width: 30%;
  border: none;
  border-radius: 100px;
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;
}

.top-tab-btn-selected :hover {
  /* background-color: #9933ff !important; */
  /* background-color: black; */

  /* color: black; */
}
.top-tab-btn-selected :active {
  color: white;
}

.top-tab-btn {
  text-align: center;
  text-decoration: none !important;
  /* background-color: #006fd0 !important; */

  font-family: "Raleway", sans-serif;

  font-size: 0.85rem;
  font-weight: 600;

  /* color: white !important; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;

  text-decoration: none;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;

  width: 30%;
  border: none;
  border-radius: 100px;
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;

  background-color: lightgray;
  color: gray;
}

.top-tab-btn :hover {
  background-color: #9933ff !important;
  color: white;
}
.top-tab-btn :active {
  color: white;
}
/* 
.top-tab-btn {
  width: 30%;

  border-radius: 100px;
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;

  border: 1px solid gray;

  background-color: lightgray;

  color: gray;
}

.top-tab-btn:hover {
  background-color: darkgray;
} */

.secondaries-container {
  margin-top: 10vh;
}

.spv-header {
  text-align: center;
  margin-bottom: 30px;
}

@media only screen and (min-width: 600px) {
  .category-img {
    display: inline-block;
    height: 12vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .work-text {
    margin: 2.5rem 4rem;
    text-align: left;
    padding-bottom: 8vh;
  }
}
/* Laptop screens:  */

@media only screen and (min-width: 600px) {
}
