.legal-text {
  /* margin: 170px; */
  padding: 30px;
  text-align: left;
}

.legal-container {
  margin-top: 100px;
  text-align: "center";
  padding: 2%;
  text-align: center;
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
}

.legal-title {
  font-size: 2.3rem;
}

@media only screen and (min-width: 600px) {
  .legal-text {
    margin-left: 15%;
    margin-right: 15%;
  }
}
