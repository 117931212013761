/* .wave-gradient {
  position: relative;
  background: linear-gradient(#8a74ea,  white);
  height: 10vh;
} */

.get-in-touch-btn-2 {
  text-align: center;
  text-decoration: none !important;
  background-color: #8a74ea;

  max-width: 200px;
  max-height: 60px;

  border-color: none;
  border-radius: 5px;

  display: flex;
  /* margin: auto; */
  margin-top: 3vh;
  margin-bottom: 5vh;
  font-family: "Raleway", sans-serif;

  font-size: 1.2rem;
  /* font-weight: 1000; */
  font-weight: 700;

  /* border: 2px solid black; */

  padding: 15px;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  /* background-color: #39a0ed; */
  /* background-color: black; */

  justify-content: center;

  text-decoration: none;
  border: none;
  /* border-radius: 5px; */
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none !important;
  margin: auto;
}

.get-in-touch-btn-2:hover {
  /* background-color: #0277bd; */
  /* background-color: #0d47a1; */
  background-color: #942aff;

  /* border: 2px solid white; */
  color: white;
}
.get-in-touch-btn-2:active {
  color: white;
}

.home-page-container {
  margin-top: 57px;
  min-height: 75vh;
  font-family: "Poppins", sans-serif;

  /* position: relative; */
}

.home-header-section {
  height: 100vh;
  /* background-color: lightgray; */
  text-align: center;
  /* text-align: center; */
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  /* margin: auto; */
}

.header-title {
  font-size: 4.3rem;
}

.header-subtitle {
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 30px;
}

.ul-test {
  padding-left: 0;
  padding: 0px 20px;
}

.header-right {
  /* display: inline-block; */
  display: relative;
  margin: auto;
  /* display: relative; */
  /* width: 50%; */
  /* margin-top: 2vh; */
}

.header-left {
  /* display: inline-block; */
  /* width: 50%; */
  margin: auto;
  /* margin-left: 8vw; */

  /* margin-top: 10vh; */
  /* margin-bottom: 2vh; */
}

.spv-header {
  text-align: center;
  margin-bottom: 30px;
}

.services-container {
  /* margin-top: 30px; */
  margin-bottom: 30px;
  padding-top: 30px;
  /* padding: 30px !important; */
  /* border: 1px solid black; */
  /* border-radius: 25pt; */
  background-color: #eeeeee !important;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.category-img-container-1 {
  text-align: center;
  height: 20vh;
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.category-img-1 {
  display: inline-block;
  width: 150px;
  height: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media only screen and (min-width: 600px) {
  .category-img-1 {
    display: inline-block;
    width: 150px;
    height: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.about-job-header {
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  padding: 25px 0px;
}

.about-description {
  /*width: 50%;*/
  padding-right: 5%;
  padding-left: 5%;
  margin-bottom: 4vh;
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  font-size: medium;
}

/* Laptop screens:  */

@media only screen and (min-width: 600px) {
  .home-header-section {
    flex-direction: row;
    text-align: left;
    height: 50vh;
    padding: 0px 10%;
  }
  .header-left {
    width: 50%;
    margin: auto;
    margin-left: 2vw;
  }

  .get-in-touch-btn-2 {
    margin: 0;
  }
}
